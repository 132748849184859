/**
 * Set the visibility of the parent_account_id field
 * @param {boolean} show
 * @param {string} prefix
 * @param {boolean} load
 * @returns {void}
 */
export default function setParentAccountVisibility(show, prefix, load = false) {
    this.dsClear();
    if (!load) {
        this.dsAdd('set', prefix + '/fields/parent_account_id/value', '');
        this.dsAdd('set', prefix + '/fields/parent_account_name/value', '');
        this.dsAdd('set', prefix + '/fields/parent_account_name/def/id_value', '');
        this.dsAdd('set', prefix + '/changes/fields/parent_account_id', '');
        this.dsAdd('set', prefix + '/changes/fields/parent_account_name', '');
    }
    this.dsAdd('set', prefix + '/fields/parent_account_name/def/visible', show);
    this.dsProcess();
}
