/**
 * Check if logged user is admin
 *
 * @this {sAction}
 *
 * @return {boolean} true if user belongs to admins
 */
export default function isAdmin() {
    const isAdmin = +this.dataGet('conf/user/is_admin'); // cast tu number for case "0" or "1"
    return !!isAdmin;
}
