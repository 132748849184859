import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';
import {Popover, Typography} from '@mui/material';

export default class ListViewPagination extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClickSqlPopover = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseSqlPopover = () => {
        this.setState({
            anchorEl: null,
        });
    };

    /**
     * setup pagination based on list type
     * @param {string} type listview type
     */
    pagination(type) {
        const data = {
            offset: this.props.offset,
            limit: this.props.limit,
            rowCount: this.props.rowCount,
            rowTotalCount: this.props.rowTotalCount,
            page: this.props.page,
            prefix: this.props.prefix,
            type: type,
        };
        sAction.listPagination(data);
    }

    /**
     *
     * @param {string} id
     * @param {string} className
     * @param {string} paginationType
     * @param {string} label
     * @returns {JSX.Element}
     */
    getPaginationBtn(id, className, paginationType, label) {
        return (
            <TooltipWrapper key={id} label={label}>
                <div
                    id={this.props.namespace + id}
                    className={'arrow icon-page' + className}
                    onClick={() => this.pagination(paginationType)}
                />
            </TooltipWrapper>
        );
    }

    render() {
        const offset = this.props.offset;
        const rowCount = +this.props.rowCount;
        const page = +this.props.page;
        const rowTotalCount = this.props.rowTotalCount;
        const view = sAction.dataGet('conf/view');

        const pagination = (
            <div className="pagination" style={view === 'detail' ? {marginTop: '-3px'} : null}>
                {page !== 1 && [
                    this.getPaginationBtn(
                        '-listFirstPage',
                        'Start',
                        'start',
                        'LBL_FIRST_PAGE',
                    ),
                    this.getPaginationBtn(
                        '-listPrevPage',
                        'Back',
                        'back',
                        'LBL_PREVIOUS_PAGE',
                    ),
                ]}
                <div className="pageCount paginationCount">
                    {page}
                </div>
                {this.props.limit === this.props.rowCount &&
                    this.getPaginationBtn(
                        '-listNextPage',
                        'Next',
                        'next',
                        'LBL_NEXT_PAGE',
                    )
                }
                {this.props.limit === this.props.rowCount && rowTotalCount != null && (
                    this.getPaginationBtn(
                        '-listLastPage',
                        'End',
                        'end',
                        'LBL_LAST_PAGE',
                    )
                )}
            </div>
        );

        const type = this.props.type;
        let pomClass = '';
        if (type === 'rightPanelQuotes') {
            pomClass = ' rightPanelQuotes';
        }
        const numbers = (offset + 1 <= rowCount + offset)? offset + 1 + '-' + (rowCount + offset) : '';

        const popoverOpen = Boolean(this.state.anchorEl);
        const popoverId = popoverOpen ? 'simple-popover' : undefined;

        return (
            <div className={'paginationContainer footerBlock' + pomClass}>
                <div className='paginationPageCount'>{numbers}</div>
                <TooltipWrapper label={'LBL_ALL_RECORDS'}>
                    {rowTotalCount === null ? (
                        <div
                            className="icon-listviewCount listviewCount"
                            onClick={() => sAction.listViewCount(this.props.prefix)}
                        />
                    ) : (
                        <div>{'\u00A0(' + sAction.formatNumber(rowTotalCount, 0) + ')\u00A0'}</div>
                    )}
                </TooltipWrapper>
                {pagination}
                {sAction.canAccessEditView() &&
                    this.props.module !== 'Activities' &&
                    this.props.module !== 'History' &&
                    this.props.isMaxSize !== true && (
                    <div className="listviewEdit">
                        <TooltipWrapper label={'LBL_EV_EDIT_LIST_VIEW'}>
                            <div
                                className="icon-EditField startEditButton"
                                onClick={() => {
                                    sAction.openDefaultListViewEdit(this.props.prefix)}
                            }
                            />
                        </TooltipWrapper>
                    </div>
                )}
                {sAction.canAccessEditView() &&
                    this.props.module !== 'Activities' &&
                    this.props.module !== 'History' &&
                    this.props.query &&
                    sAction.getStorage('debug') && (
                    <div className="listviewSql">
                        <div
                            className="icon-databaseGrey showSqlButton"
                            onClick={this.handleClickSqlPopover}
                        ></div>
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleCloseSqlPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography
                                sx={{p: 2}}
                                dangerouslySetInnerHTML={{
                                    __html: this.props.query?.replace(/\n/g, '<br/>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'),
                                }}
                            >
                            </Typography>
                        </Popover>
                    </div>
                )}
            </div>
        );
    }
}

ListViewPagination.propTypes = {
    isMaxSize: PropTypes.bool,
    limit: PropTypes.number.isRequired,
    module: PropTypes.string,
    offset: PropTypes.number,
    page: PropTypes.number.isRequired,
    prefix: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rowTotalCount: PropTypes.number,
    type: PropTypes.string,
    namespace: PropTypes.string,
    query: PropTypes.string,
};
