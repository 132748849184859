/**
 * @this {sAction}
 *
 * @return {boolean} true if we are on package instance
 */
export default function isPackageVersion(){
    const be_branch = this.dataGet('conf/be_branch');
    if(be_branch === 'package_master'){
        return true;
    }
    return false;
}