/**
 *
 * Returns default EV level for logged user
 *
 * @this {sAction}
 *
 * @return {string} level
 */
export default function defaultEditViewLevel() {
    if(this.isPackageVersion()){
        return 'third';
    }

    if(this.isAcmAdmin()){
        return 'custom';
    }
    return 'third';
}
